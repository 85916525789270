import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";
import { Outlet } from "react-router";

import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";
import { CoreStoreInstance } from "@shoothill/core";

export const Root = styled.div`
    animation: fadeIn 1s;
    display: flex;
    height: 100%;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const ScrollableSection = styled.section`
    display: flex;
    flex: 1;
    overflow-y: auto;
`;

const BackgroundSection = styled.section`
    background: linear-gradient(#f5faff, #deedff);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex: 1;
`;

const ImageSection = styled.div`
    background: url(resources/wild_otter_logo.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
    flex: 1;
`;

const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const AccountLayout: React.FC<{}> = observer((props) => {
    if (!props) {
        return <>Props undefined</>;
    }

    if (window.IE11) {
        return <UnsupportedBrowserView />;
    }

    return (
        <Root>
            <ScrollableSection>
                <Content>
                    <Outlet />
                </Content>
            </ScrollableSection>
            {CoreStoreInstance.isMobile || (
                <BackgroundSection>
                    <ImageSection />
                </BackgroundSection>
            )}
        </Root>
    );
});
