import React from "react";

import { ApplicationRoot } from "./ApplicationStyles";
import { ApplicationLogo } from "./ApplicationLogo";

export const ApplicationBusy: React.FC = () => {
    return (
        <ApplicationRoot>
            <div>
                <div className="logo-container">
                    <ApplicationLogo className="rotate" />
                </div>
                <div className="message">Please stand by</div>
            </div>
        </ApplicationRoot>
    );
};
