import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { container } from "tsyringe";

// Session Expired view must be imported before Common Injects, otherwise get whitescreen.
import { SessionExpiredView } from "Views/SessionExpired/SessionExpiredView";
import { ApplicationBusy, PrivateRoute } from "Application";
import { CommonInjects } from "Application/CommonInjects";
import { AppUrls } from "AppUrls";
import { DebugRouter } from "Components/DebugRouter";
import { AccountStore } from "Stores/Domain";
import { Error } from "Views/Error/Error";
import { DefaultLayout } from "Views/Layouts";
import { AccountLayout } from "Views/Layouts/AccountLayout";

const AccountRoutes = React.lazy(() => import("Views/Account/AccountRoutes"));
const ClubsRoutes = React.lazy(() => import("Views/Clubs/ClubsRoutes"));
const ContentsRoutes = React.lazy(() => import("Views/Contents/ContentsRoutes"));
const SettingsRoutes = React.lazy(() => import("Views/Settings/SettingsRoutes"));
const UsersRoutes = React.lazy(() => import("Views/Users/UsersRoutes"));

export const AppRoutes: React.FC = () => {
    const accountStore = container.resolve(AccountStore);

    return (
        <>
            <DebugRouter>
                <Routes>
                    {/* These routes are only available if the user has logged in. */}
                    <Route element={<PrivateRoute isAllowed={() => accountStore.IsLoggedIn} redirectPath={AppUrls.Client.Account.Login} />}>
                        <Route path={"/"} element={<DefaultLayout />}>
                            {/* Having logged in, navigate to the users page. */}
                            <Route index element={<Navigate to={AppUrls.Client.Users.Users} replace />} />

                            <Route path={AppUrls.Client.Account.SessionExpired} element={<SessionExpiredView />} />
                            <Route path={AppUrls.Client.Error} element={<Error supportEmail={"support@shoothill.com"} />} />

                            <Route
                                path={"/clubs/*"}
                                element={
                                    <React.Suspense fallback={<ApplicationBusy />}>
                                        <ClubsRoutes />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path={"/contents/*"}
                                element={
                                    <React.Suspense fallback={<ApplicationBusy />}>
                                        <ContentsRoutes />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path={"/settings/*"}
                                element={
                                    <React.Suspense fallback={<ApplicationBusy />}>
                                        <SettingsRoutes />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path={"/users/*"}
                                element={
                                    <React.Suspense fallback={<ApplicationBusy />}>
                                        <UsersRoutes />
                                    </React.Suspense>
                                }
                            />
                        </Route>
                    </Route>

                    <Route element={<AccountLayout />}>
                        <Route
                            path="/account/*"
                            element={
                                <React.Suspense fallback={<ApplicationBusy />}>
                                    <AccountRoutes />
                                </React.Suspense>
                            }
                        />
                    </Route>

                    <Route
                        path={"*"}
                        element={
                            <main style={{ padding: "1rem" }}>
                                <p>There's nothing here!</p>
                            </main>
                        }
                    />
                </Routes>
            </DebugRouter>

            <CommonInjects />
        </>
    );
};
