import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { CSSProperties, FocusEventHandler, useRef, useState } from "react";
import { IMaskInput } from "react-imask";

import { Box, ErrorExclamation, ICommand, IKeyState, Label, RelayCommand } from "Application";
import { PasswordSVG } from "Assets/Icons/PasswordSVG";
import { PasswordSVGVisible } from "Assets/Icons/PasswordSVGVisible";
import { FieldLabel, InputRoot } from "../CommonStyles";
import { ClearText } from "../../General/ClearText";

/**
 * Input interface.
 */
interface IInputBaseProps {
    /**
     * An optional id for use with the button.
     */
    id?: string;
    /**
     * An optional class name for use with the control.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the control. Will be passed back by the command.
     */
    value: () => string | number;
    /**
     * Text content to display in the control label.
     */
    displayName?: string;
    /**
     * Text content to display in the placeholder.
     */
    placeholder?: string;
    /**
     * Text content to display in the error message.
     */
    validationMessage?: () => string;
    /**
     * An icon to display on the button.
     */
    iconName?: string;
    /**
     * Treats the component as a textarea.
     */
    multiline?: boolean;
    /**
     * Number of rows in a multiline box
     */
    rows?: number;
    /**
     * Forces browsers to not fill the text
     */
    autoFill?: boolean;
    /**
     * Styling of the control.
     */
    style?: CSSProperties | undefined;
    /**
     * The description text to show under the text.
     */
    description?: string;
    /**
     * Any JSX Element
     */
    prefix?: JSX.Element;
    /**
     * Any JSX Element
     */
    suffix?: JSX.Element;
    /**
     * The control type - password or text.
     */
    type?: "password" | "text" | "email" | "number";
    /**
     * Masked options for the input.
     */
    maskedOptions?: any;
    /**
     * Set if you want to disable the input.
     */
    readonly?: boolean;
    /**
     * Use to make cypress testing easier
     */
    cy?: string;

    noHeader?: boolean;
    maxLength?: number;
    autoFocus?: boolean;
    uppercaseLabel?: boolean;
    onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
    onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
}

export type Ref = HTMLButtonElement;

export const Input: React.FC<IInputBaseProps> = observer((props: IInputBaseProps) => {
    // #region Code Behind

    const haveError = !isEmptyOrWhitespace(props.validationMessage?.() as string);
    const isMultiLine = !isNullOrUndefined(props.multiline) ? props.multiline : false;
    const [type, setType] = useState(props.type);
    const keyState = useRef<IKeyState>({ enterKeyPressed: false, backspaceKeyPressed: false, deleteKeyPressed: false });

    const getClassName = () => {
        return clsx({
            ["input-container"]: true,
            [`input-container-${props.className}`]: !isEmptyOrWhitespace(props.className),
            ["input-container-clearable"]: canDisplayClear(),
        });
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const onChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        let newValue = e.currentTarget.value;
        updateValue(newValue);
    };

    const updateValue = (newValue: any) => {
        if (!isEmptyOrWhitespace(newValue.toString()) && newValue.toString()!.length > props.maxLength!) {
            return;
        }

        props.command.execute(newValue, keyState.current);
    };

    const getAutoFill = (): "on" | "off" | "new-password" => {
        return isNullOrUndefined(props.autoFill) ? "on" : props.autoFill ? "on" : "new-password";
    };

    const getStyles = (): {} | undefined => {
        return !isNullOrUndefined(props.style) ? props.style : undefined;
    };

    const getType = (): string => {
        return isEmptyOrWhitespace(type) ? "text" : type!;
    };

    const canDisplayClear = (): boolean => {
        if (!isNullOrUndefined(props.value())) {
            return !isEmptyOrWhitespace(props.value().toString()) && !isDisabled();
        }
        return false;
    };

    const canDisplayEyeIcon = (): boolean => {
        let retVal = false;
        if (!isNullOrUndefined(props.value()) && props.type === "password") {
            retVal = !isEmptyOrWhitespace(props.value().toString()) && !isDisabled();
        }
        return retVal;
    };

    const canDisplayDescription = (): boolean => {
        return !isEmptyOrWhitespace(props.description);
    };

    const handleEyeClick = () => {
        setType(type === "password" ? "text" : "password");
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement> | undefined): void => {
        let keyId: number = event!.keyCode;

        switch (keyId) {
            case 8:
                keyState.current.backspaceKeyPressed = true;
                keyState.current.enterKeyPressed = false;
                keyState.current.deleteKeyPressed = false;
                break;
            case 13:
                keyState.current.backspaceKeyPressed = false;
                keyState.current.enterKeyPressed = true;
                keyState.current.deleteKeyPressed = false;
                break;
            case 46:
                keyState.current.backspaceKeyPressed = false;
                keyState.current.enterKeyPressed = false;
                keyState.current.deleteKeyPressed = true;
                break;
            default:
                break;
        }
        if (keyId === 8 || keyId === 13 || keyId === 46) {
            updateValue(props.value());
        }
    };

    const clearTextCommand = new RelayCommand(() => {
        props.command.execute("");
    });

    const getDataCY = (): string => {
        let regex = /[^a-z]/gi;
        let result = (props.displayName || "").replace(regex, "");
        return props.cy || result;
    };

    // #endregion Code Behind

    const getEyeIcon = () => {
        if (type === "password") {
            return <PasswordSVG onClick={handleEyeClick} />;
        }

        return <PasswordSVGVisible onClick={handleEyeClick} />;
    };

    const displayLabel = (
        <Box flexBox>
            <FieldLabel htmlFor={props.id} className="placeholder">
                {props.displayName}
            </FieldLabel>
            <ErrorExclamation haveError={haveError} validationMessage={() => getValidationMessage()} />
        </Box>
    );

    const textArea = (
        <Box className={getClassName()} showIf={isMultiLine}>
            <textarea
                autoComplete={getAutoFill()}
                autoFocus={props.autoFocus}
                className={getClassName()}
                data-cy={props.cy!}
                disabled={isDisabled()}
                id={props.id}
                onBlur={props.onBlur}
                onChange={onChange}
                onFocus={props.onFocus}
                placeholder={props.placeholder}
                prefix={props.prefix as any}
                rows={props.rows}
                style={getStyles()}
                value={isNullOrUndefined(props.value()) ? "" : props.value()}
            ></textarea>
            {canDisplayClear() && <ClearText command={clearTextCommand} />}
        </Box>
    );

    const input = (
        <Box className={getClassName()} showIf={!isMultiLine && !props.readonly}>
            <IMaskInput
                {...props.maskedOptions}
                autoComplete={getAutoFill()}
                autoFocus={props.autoFocus}
                className={getClassName()}
                data-cy={getDataCY()}
                disabled={isDisabled()}
                id={props.id}
                maxLength={props.maxLength || 1000}
                onAccept={(value, mask) => {
                    updateValue(mask.unmaskedValue);
                }}
                onChange={() => null}
                onKeyDown={onKeyDown}
                placeholder={props.placeholder}
                readOnly={props.readonly}
                type={getType()}
                unmask={"typed"}
                value={isNullOrUndefined(props.value()) ? "" : props.value()}
            />
            {canDisplayClear() && <ClearText command={clearTextCommand} />}
            {canDisplayEyeIcon() && <Box className="password">{getEyeIcon()}</Box>}
        </Box>
    );

    return (
        <>
            {!props.readonly ? (
                <InputRoot>
                    {!props.noHeader && displayLabel}
                    {textArea}
                    {input}
                    {canDisplayDescription() && <p className="description">{props.description}</p>}
                </InputRoot>
            ) : (
                <Box showIf={props.readonly} flexBox alignItems={"self-end"}>
                    <Label>{props.value()}</Label>
                </Box>
            )}
        </>
    );
});

Input.defaultProps = {
    maxLength: 10000,
    maskedOptions: { mask: /.*/ },
};
