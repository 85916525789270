import React from "react";

interface IProps {
    className?: string;
}

export const ApplicationLogo: React.FC<IProps> = (props) => {
    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="1200px" height="1200px" viewBox="0 0 1200 1200">
            <path fill="#2fb7ec" d="M927.939 700.332c31.12-14.021 59.83-5.155 85.461 14.355-20.336 6.578-58.546 13.492-85.461-14.355" data-name="Path 8615" />
            <path fill="#008ad9" d="M1161.769 863.355c-11.417 41.042-37.207 84.141-66.55 114.593 31.876-33.108 31.824-89.138 66.55-114.593" data-name="Path 8616" />
            <path
                fill="#008ad9"
                d="M867.735 877.446c35.821-7.916 15.1 43.342-5.343 54.411-85.162 46.091-170.307 53.131-222.906 48.378 91.882-10.063 81.08-70.279 228.253-102.789"
                data-name="Path 8617"
            />
            <path
                fill="#008ad9"
                d="M626.363 978.986c-105.727 17.433-403.081-152.961-294.926-221.182 36.5-23.01 90.387-10.5-16.272-96.561 53.32 48.588 156.844 34.779 161.756 73.235 19.122 59.988-33.776 90.9-75.592 117.9 16.448.4 31.63-5.524 46.372-11.839 54.623 30.821 107.785 44.7 161.932 42.168 0 0-42.22 50.049 16.73 96.28"
                data-name="Path 8618"
            />
            <path
                fill="#008ad9"
                d="m80.542 804.772.246.193c-22.025-73.493-100.466-39.193-76.172 29.207 29.009 81.66 104.953 135.121 173.191 136.476-44.542-47.657-78.952-102.576-97.265-165.876"
                data-name="Path 8619"
            />
            <path
                fill="#008ad9"
                d="M285.399 235.856c-222.202 330.233 3.747 446.163-52.213 457.633-90.017 13.546-119.325-280.853 3.905-482 66.129-107.92 327.507-263.12 637.052-119.762-81.4-37.7-430.049-91.724-588.744 144.129"
                data-name="Path 8620"
            />
            <path fill="#aaddf6" d="M78.238 408.906c161.51-474.433 734.176-518.588 990.576-222.5C905.246 10.839 319.091-159.1 78.238 408.906" data-name="Path 8621" />
            <path
                fill="#aaddf6"
                d="M941.168 1112.296c16.044 7.635 99.376 8.2 128.3-5.559-63.175 30.029-120.085 44.806-169.553 26.988 13.9-6.843 27.742-13.915 41.253-21.427"
                data-name="Path 8622"
            />
            <path
                fill="#008ad9"
                d="M1100.62 1082.038c-28.815 28.358-117.266 30.61-145.345 22.465 38.95-22.148 74.873-52.177 102.1-87.77 57.666-68.925 72.056 36.943 43.241 65.3"
                data-name="Path 8623"
            />
            <path
                fill="#2fb7ec"
                d="M817.111 724.591c-2.445-24.206-3.624-52 6.7-73.182 8.339-19.861 42.924-21.444 48.536-4.433-36.8 32.422 6.456 70.912 43.645 57.068 30.768 35.659 77.175 29.924 110.089 17.574 31.736 14.337 85.426 24.787 69.593-41.517 66.11-28.024 84.863 46.46 77.474 102.3-.9 15.727-5 55.643-23.907 78.477-46.442 61.114-29.255 82.382-94.45 140.646-148.58 161.809-317.919 173.35-405.6 173.35-480.2 0-789.009-530.709-432.547-946.051-134.483 237.028-68.778 512.48 22.154 479.97 72.812-26.036-75.785-87.7-26-285.8-16.66 235.435 141.103 246.659 148.389 299.874 3.958 28.833-163.709 24.822.862 167.052 164.568 142.211 372.521 130.442 508.576 52.405a56.488 56.488 0 0 0 26.9-61.941c-7.987-34.638-61.061-32.017-92.2-27.813 17.152-37.224 23.309-87.783 11.787-127.98"
                data-name="Path 8624"
            />
            <path
                fill="#008ad9"
                d="M1187.945 738.013c-.827 3.114-1.742 6.245-2.832 9.288-6.931-26.106 2.674-97.74-92.867-75.275a53.1 53.1 0 0 0-21.638-16.994c26.159 31.014 23.942 75.223-21.691 57.859-30.311-10.133-54.534-40.232-89.313-36.714-23.855-.035-44.525 15.938-67.975 14.5-35.5-7.793-8.761-44.982 6.878-56.716a40.727 40.727 0 0 0-16.958 6c-6.333-12.93-28.675-20.811-44.824-12.965 108.981-157.926 360.526-84.973 351.22 111.017M920.48 618.248c-8.356-10.819-25.35-13.915-38.069-10.186-3.712-.123-13.546 4.943-12.877-.6-9.025 10.467 18.348 11.153 24.365 11.188 13.088 1.055 23.309 8.057 33.442 16.994.035-6.263-2.621-12.261-6.861-17.4m204.382 17.016c-20.6-5.366-55.942-14.109-65.125 12.7 22.8-16.4 60.445 16.378 74.888-12.789a21.053 21.053 0 0 1-9.764.088"
                data-name="Path 8625"
            />
            <path fill="#2fb7ec" d="M1178.305 639.359a189.953 189.953 0 0 0-15.674-28.024c19.316 1.935 35.377 15.41 15.674 28.024" data-name="Path 8626" />
            <path
                fill="#2fb7ec"
                d="M835.619 606.867c-10.4-20.882 12.172-21.603 26.773-24.543-8.145 9.447-15.006 19.421-23.309 27.32a6.247 6.247 0 0 1-3.464-2.777"
                data-name="Path 8627"
            />
            <path
                fill="#2fb7ec"
                d="M742.873 864.288c-4.38 14.795 104.812-18.331 131.27 2.269-165.42 41.165-164.852 107.416-224.365 95.611-59.513-11.786-15.569-90.95-1.5-96.667 32.756-19.316 78.3-17.047 109.4-32.105 25.367-19.685 44.12-51 51.667-82.506 10.625 29.009 6.351 90.369-16.976 102.982-23.327 12.631-45.123-4.38-49.5 10.414"
                data-name="Path 8628"
            />
            <path
                fill="#008ad9"
                d="m963.122 197.436-.123-.106c11.611 39.617 51.491 22.078 40.954-15.5-9.623-34.322-55.291-59.02-92.568-73.921 55.995 42.59 42.08 55.432 51.737 89.525"
                data-name="Path 8629"
            />
            <path
                fill="#2fb7ec"
                d="m1036.322 244.986-.053-.088c-2.48 24.928 25.4 24.277 26.581.6 1.39-28.257-14.85-53.815-35.236-62.664 7.512 19.914 11.048 40.76 8.708 62.152"
                data-name="Path 8630"
            />
            <path
                fill="#aaddf6"
                d="m1061.285 152.577-.053-.018c7.037 10.274 17.433 1.108 10.309-8.268-8.515-11.206-22.869-15.709-33.46-12.56a66.32 66.32 0 0 1 23.2 20.846"
                data-name="Path 8631"
            />
        </svg>
    );
};
