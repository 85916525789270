import React from "react";
import { Main } from "./UnsupportedBrowser.styles";
import { Box } from "../../Components";

export const UnsupportedBrowserView: React.FC = () => {
    return (
        <Main className="container2 text-center">
            <Box padding={"0 30px 0 30px"} className="grid-tools FullSite">
                <div className="container2">
                    <div className="container-wrapper">
                        <div className="container-full-column">
                            <div className="unsupported-browser">
                                <h1>Please upgrade your browser in order to use this website</h1>
                                <br />
                                <p>
                                    We built it using the latest technology which improves the look of the site, increases the speed of the site, and gives you a better experience
                                    with new features and functions. Unfortunately, your browser does not support these technologies.
                                </p>
                                <br />
                                <h2>Please download one of these free and up-to-date browsers:</h2>
                                <br />
                                <ul>
                                    <li>
                                        <a href="https://www.google.com/chrome/browser/desktop/" target="_blank">
                                            Chrome
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.mozilla.com/firefox/" target="_blank">
                                            Firefox
                                        </a>
                                    </li>

                                    <li>
                                        <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank">
                                            Microsoft Edge
                                        </a>
                                    </li>
                                </ul>
                                <hr />
                                <div className="unsupported-message">
                                    <h3>I can't update my browser</h3>
                                    <br />
                                    <ul>
                                        <li>Ask your system administrator to update your browser if you cannot install updates yourself.</li>
                                        <li>
                                            If you can't change your browser because of compatibility issues, think about installing a second browser for utilization of this site
                                            and keep the old one for compatibility.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </Main>
    );
};
