import styled from "@emotion/styled";

interface Props {
    padding?: string;
}

export const DashedLine = styled.hr<Props>`
    border: none;
    border-top: 1px dashed #008ad9;
    height: 1px;
    width: 100%;
    margin-top: ${(p) => (p.padding ? p.padding : "1.5rem")};
    margin-bottom: ${(p) => (p.padding ? p.padding : "1.5rem")};
`;
