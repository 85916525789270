import styled from "@emotion/styled";
import { theme } from "../../Styles";

/**
 * Styling for input/select labels.
 */
export const FieldLabel = styled.label`
    font-family: ${theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily};
    font-size: ${theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize};
    font-weight: ${theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight};
    letter-spacing: ${theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing};
    line-height: ${theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight};

    color: ${theme.palette.field.text}
    display: block;
    margin-bottom: 0;
    margin-top: 0.25rem;
    min-height: 1.25rem;
    text-transform: uppercase;
`;

/**
 * Styling for validation error that appears next to input/select labels.
 */
export const ErrorExclamationViewRoot = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-bottom: 0.125rem;
    margin-left: 0.625rem;

    svg {
        outline: none;
    }
`;

/**
 * Styling for clear text button that appears next to input/select labels.
 */
export const ClearTextRoot = styled.div`
    color: #aaa;
    cursor: pointer;
    font-size: 0.825rem;
    font-weight: bold;
    min-width: 1.5rem;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0.5625rem;

    ::before {
        content: "\\2715";
    }

    &:hover {
        color: #d0021b;
    }
`;

/**
 * Styling for input controls.
 */
export const InputRoot = styled.div`
    align-self: end;
    display: flex;
    flex-direction: column;

    .input-container {
        align-items: center;
        background-color: white;
        border-radius: 0;
        border: 1px solid ${theme.palette.field.main};
        box-sizing: content-box;
        display: flex;
        flex-direction: row;
        transition: 0.5s ease all;

        input,
        textarea {
            color: ${theme.palette.field.text};
            font-family: ${theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily};
            font-size: ${theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize};
            font-weight: ${theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight};
            letter-spacing: ${theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing};
            line-height: ${theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight};
            text-transform: ${theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform};

            -moz-appearance: none;
            -ms-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            border: 0;
            outline: 0;
            overflow: hidden;
            padding: 10px;
            text-overflow: ellipsis;
            transition: 0.5s ease all;
            width: 100%;

            ::-ms-reveal,
            ::-ms-clear {
                display: none;
            }

            ::placeholder {
                color: #8d8d8d;
            }

            &:disabled {
                color: #8d8d8d;
                background-color: #eee;
            }
        }

        input {
            white-space: nowrap;
        }

        textarea {
            overflow-y: auto;
            white-space: pre-wrap;
        }

        &:focus,
        &:hover {
            outline: 1px solid ${theme.palette.common.focus} !important;
        }

        &.input-container-clearable {
            position: relative;

            input {
                padding-right: 1.5rem;
            }
        }

        &.input-clearable-password {
            position: relative;

            input {
                padding-right: 3rem;
            }
        }
    }

    .input-container-date {
        .rdt {
            width: 100%;

            .rdtPicker {
                font-size: 0.75rem;

                .rdtTime {
                    .rdtBtn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }

    .password {
        position: absolute;
        top: 0.625rem;
        right: 1.5rem;
        min-width: 1.5rem;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            fill: #aaa;

            &:hover {
                fill: #d0021b;
            }
        }
    }

    .description {
        color: #8d8d8d;
        font-size: 0.625rem;
        margin-top: 0.375rem;
        text-align: right;
        text-transform: uppercase;
    }
`;

/**
 * Styling for autoComplete controls.
 */
export const EditSelectv2Root = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;

    div.clear {
        color: #999999;
        font-size: 1.1875rem;
        height: 1.875rem;
        margin-top: -1px;
        right: 31px;
        text-align: center;
        top: 1px;
        width: 1.875rem;

        &:hover {
            color: #d0021b;
            cursor: pointer;
        }

        // IE11 hack to adjust position of clear button.
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            margin-top: 1px;
        }

        position: absolute;
    }

    div.editselect-container {
        flex-grow: 1;

        div.editselect__control {
            border: 1px solid #ced4da;
            border-radius: 0;
            box-shadow: none;
            transition: 0.5s ease all;            
            min-height: 0;

            &:focus,
            &:hover {
                outline: 1px solid ${theme.palette.common.focus} !important;
            }

            > div.editselect__value-container {
                /* padding: 0.1875rem 0.5rem; */
                /* padding: 0.125rem 0.75rem; */
                white-space: nowrap !important;
                /* height: calc(1.875rem - 2px); */
                padding: 10px 1.5rem 10px 10px;

                > div.editselect__placeholder,
                > div.editselect__single-value,
                > div.editselect__multi-value > div.editselect__multi-value__label,
                > div.editselect__input-container {
                    padding: 0;
                    margin: 0;
                    font-size: ${theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize};

                    > input.editselect__input {
                        font-size: ${theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize};
                    }
                }

                > div.editselect__placeholder {
                    color: #8d8d8d;
                }
            }

            > div.editselect__indicators {
                > .editselect__indicator-separator {
                    display: none;
                }

                > .editselect__indicator {
                    align-items: center;
                    color: #999999;
                    height: 1.875rem;
                    justify-content: center;
                    margin: -1px;
                    padding: 0.375rem;
                    width: 1.875rem;

                    &:hover {
                        color: #d0021b;
                        cursor: pointer;
                    }
                }
            }
        }

        &.editselect--is-disabled {
            div.editselect__control {
                color: #8d8d8d;
                background-color: #eee;
            }
        }

        > div.editselect__menu {
            border: 1px solid ${theme.palette.field.light};
            border-radius: 0;
            box-shadow: none;
            margin-top: -1px;

            .editselect__menu-list {
                padding: 0;

                .editselect__option {
                    font-size: ${theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize} !important;
                    padding: 0.6875rem 0.75rem;

                    &:(.editselect__option--is-selected):hover {
                        background-color: #e8f2fb;
                        color: black;
                        cursor: pointer;
                    }
                }

                .editselect__option--is-focused {
                    background-color: #e8f2fb;
                }

                .editselect__option--is-selected {
                    background-color: #2d7dcc;
                }
            }
        }
    }
`;

/**
 * Styling for checkbox controls.
 */
export const CheckBoxRoot = styled.label`
    align-items: center;
    display: grid;
    font-family: system-ui, sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    gap: 1rem;
    grid-template-columns: 1em auto;
    line-height: 1.1;
    padding-bottom: 0.25rem;
    padding-top: 1.5rem;

    input[type="checkbox"] {
        cursor: pointer;
    }

    input[type="checkbox"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        /* Remove most all native input styles */
        -moz-appearance: none;
        appearance: none;
        /* For iOS < 15 */
        background-color: white;
        /* Not removed via appearance */
        border-radius: 0;
        border: 1px solid ${theme.palette.field.main};
        color: currentColor;
        display: grid;
        font: inherit;
        height: 2rem;
        margin: 0;
        place-content: center;
        width: 2rem;
    }

    input[type="checkbox"]::before {
        -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        box-shadow: inset 1em 1em ${theme.palette.common.secondary};
        content: "";
        height: 1rem;
        transform-origin: center;
        transform: scale(0);
        transition: 250ms transform ease-in-out;
        width: 1rem;
    }

    input[type="checkbox"]:checked::before {
        transform: scale(1);
    }

    input[type="checkbox"]:focus,
    input[type="checkbox"]:hover {
        outline: 1px solid ${theme.palette.common.focus} !important;
    }

    input[type="checkbox"]:disabled {
        background-color: #eee;
        cursor: not-allowed;
    }

    input[type="checkbox"]:disabled::before {
        box-shadow: inset 1em 1em #8d8d8d;
    }

    label {
        pointer-events: none;
    }
`;

/**
 * Styling for radio buttons group controls.
 */
export const RadioButtonsRoot = styled.div`
    display: flex;
    flex-direction: column;

    &.radiobuttons-horizontal {
        div.radiobutton-container {
            display: flex;
            position: relative;

            label.radiobutton:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }
`;

/**
 * Styling for radio button controls.
 */
export const RadioButtonRoot = styled.label`
    align-items: center;
    display: grid;
    font-family: system-ui, sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    gap: 1rem;
    grid-template-columns: 1em auto;
    line-height: 1.1;
    padding-bottom: 0.25rem;

    input[type="radio"] {
        cursor: pointer;
    }

    input[type="radio"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        /* Remove most all native input styles */
        -moz-appearance: none;
        appearance: none;
        /* For iOS < 15 */
        background-color: white;
        /* Not removed via appearance */
        border-radius: 50%;
        border: 1px solid ${theme.palette.field.main};
        color: currentColor;
        display: grid;
        font: inherit;
        height: 2rem;
        margin: 0;
        place-content: center;
        width: 2rem;
    }

    input[type="radio"]::before {
        border-radius: 50%;
        content: "";
        height: 1.25rem;
        transform-origin: center;
        transform: scale(0);
        transition: 250ms transform ease-in-out;
        width: 1.25rem;
    }

    input[type="radio"]:checked::before {
        background-color: ${theme.palette.secondary.main};
        transform: scale(1);
    }

    input[type="radio"]:focus,
    input[type="radio"]:hover {
        outline: 1px solid ${theme.palette.common.focus} !important;
    }

    input[type="radio"]:disabled {
        background-color: #eee;
        cursor: not-allowed;
    }

    input[type="radio"]:disabled::before {
        box-shadow: inset 1em 1em #8d8d8d;
    }
`;

/**
 * Styling for radio icon button controls.
 */
export const RadioIconButtonRoot = styled.label`
    align-items: center;
    display: grid;
    font-family: system-ui, sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    gap: 2.25rem;
    grid-template-columns: 1em auto;
    line-height: 1.1;
    padding-bottom: 0.25rem;

    input[type="radio"] {
        cursor: pointer;
    }

    input[type="radio"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        /* Remove most all native input styles */
        -moz-appearance: none;
        appearance: none;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        /* For iOS < 15 */
        background-color: white;
        /* Not removed via appearance */
        border-radius: 50%;
        color: currentColor;
        display: grid;
        font: inherit;
        height: 3rem;
        margin: 0;
        place-content: center;
        width: 3rem;
    }

    input[type="radio"]::before {
        border-radius: 50%;
        content: "";
        height: 3rem;
        transform-origin: center;
        transform: scale(0);
        transition: 250ms transform ease-in-out;
        width: 3rem;
    }

    input[type="radio"]:checked::before {
        border: 2px solid #ffc400;
        transform: scale(1);
    }

    input[type="radio"]:focus,
    input[type="radio"]:hover {
        outline: 1px solid ${theme.palette.common.focus} !important;
    }

    input[type="radio"]:disabled {
        background-color: #eee;
        cursor: not-allowed;
    }

    input[type="radio"]:disabled::before {
        box-shadow: inset 1em 1em #8d8d8d;
    }
`;

export const RadioButonButtonRoot = styled.label`
    input[type="radio"] {
        opacity: 0;
        position: absolute;
    }

    input[type="radio"] + div {
        border: 2px solid transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(1);

        label {
            border: 3px solid white;
            cursor: pointer;
            font-size: 0.625rem;
            padding: 4px 8px;
            text-transform: uppercase;
        }
    }

    input[type="radio"]:checked + div {
        border-color: #ffc400;
    }

    input[type="radio"]:focus + div,
    input[type="radio"]:hover + div {
        outline: 1px solid ${theme.palette.common.focus} !important;
    }

    input[type="radio"]:disabled + div {
        label {
            color: #aaa !important;
            cursor: not-allowed;
            background-color: #eee !important;
        }
    }
`;

/**
 * Styling for find address controls
 */
export const FindAddressRoot = styled.div`
    .search-button {
        display: flex;
        align-items: end;

        > button {
            background-color: #20a8e7;
            background-image: url(resources/search_white_24dp.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 60%;

            min-height: 2.5rem;
            min-width: 2.5rem;
            padding: 0;
        }
    }
`;

/**
 * Styling for files controls
 */
export const FilesRoot = styled.div`
    .file-button {
        display: flex;
        aspect-ratio: 1;

        button {
            align-items: center;
            background-color: #F9F9F9;
            color: #8D8D8D;
            border: 1px dashed ${theme.palette.field.main};
            display: flex;
            flex-direction: column;
            flex: 1;
            font-family: ${theme.defaultFontStyle.fontFamily};
            /* font-size: 10px; */
            /* height: 80px; */
            justify-content: center;
            letter-spacing: 0.36;
            text-transform: uppercase;

            :hover {
                cursor: pointer;
            },
        }

        &.file-button-error {
            button {
                background-color: #EF9099;
                color: #8f1924;
            }
        }
    }

    .file-container: {
        max-height: 180px;
        overflow-y: auto;
    }
`;

/**
 * Styling for individual file controls
 */
export const FileRoot = styled.div`
    align-items: center;
    border: 1px solid ${theme.palette.field.main};
    display: flex;
    /* height: 4rem; */
    justify-content: space-between;
    position: relative;

    .fileinformation {
        flex-direction: column;
        align-items: center;
        display: flex;

        p,
        a {
            display: inline-block;
            font-size: 0.625rem;
            word-break: break-all;
        },

        .image-container {
            
        }

        .information-container {
            display: flex;
            width: 100%;
            justify-content: space-between;
            /* padding: 0 1.5rem 0 0.5rem; */

            .fileinformation-text {
                display: flex;
                flex-direction: column;
                padding: 0.25rem;

                > * {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .clear-file {
                padding: 0.25rem;
                padding-left: 0;
            }
        }
    
        img,
        video {
            width: 100%;
            object-fit: cover;
            aspect-ratio: 1;
            vertical-align: middle;
        }
    },
    .fileicon {
        margin: 9px;
    },
    .documenturl {},
    .filesizebytes {},
    .clear-button {},
`;

type SingleFileRootProps = {
    noHideOnHover?: boolean;
};

/**
 * Styling for individual file controls
 */
export const SingleFileRoot = styled.div<SingleFileRootProps>`
    border-radius: 0;
    min-height: 15rem;
    overflow: hidden;
    position: relative;

    .file-button {
        align-items: center;
        background-color: #f9f9f9;
        border: 1px dashed ${theme.palette.field.main};
        display: flex;
        flex-direction: column;
        flex: 1;
        font-family: ${theme.defaultFontStyle.fontFamily};
        font-size: 10px;
        min-height: inherit;
        justify-content: center;
        letter-spacing: 0.36;
        text-transform: uppercase;
        width: 100%;

        :hover {
            cursor: pointer;
        },
    }

    .file-button-image {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        div {
            display: none;
        }

        ${(props) =>
            props.noHideOnHover ||
            `
            :hover {
                background-image: none !important;

                div {
                    display: flex;
                }            
            }
        `}
    }

    .file-button-delete {
        background-color: #e5e5e5;
        color: #c53030;
        min-height: 1.5rem;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 1.5rem;
    }
`;
