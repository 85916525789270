import { css, Global } from "@emotion/react";
import { theme } from "./AppTheme";

export const GlobalStyles = () => {
    const scrollbar_backgroundcolor = theme.palette.primary.light;
    const scrollbar_backgroundbordercolor = "#DCDCDC";

    return (
        <Global
            styles={css`
                html,
                body {
                    height: 100%;
                    margin: 0;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    text-rendering: optimizeLegibility;
                    background-color: white !important;

                    font-family: ${theme.defaultFontStyle.fontFamily};
                    font-size: ${theme.defaultFontStyle.fontSize};
                    line-height: ${theme.defaultFontStyle.lineHeight};
                }
                #root {
                    // These 4 properties are required for correct scroll behaviour.
                    // Do not touch.
                    height: 100%;
                    display: flex;
                    flex: 1;
                    flex-direction: column;

                    // Other properties.
                }
                code {
                    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
                }
                .draggable-item {
                    &:not(.disabled) {
                        cursor: pointer;
                    }

                    &.disabled {
                        opacity: 0.35;

                        img {
                            pointer-events: none;
                        }
                    }
                }

                @media (min-width: 601px) {
                    /////////////////////////////////////////
                    //Custom scroll bar
                    * {
                        scrollbar-width: thin;
                        scrollbar-color: ${scrollbar_backgroundcolor} ${scrollbar_backgroundbordercolor};
                    }

                    // Chrome, Edge, and Safari - more overrides available, including radius, width and thumb borders.
                    *::-webkit-scrollbar {
                        height: 9px;
                        width: 9px;
                    }

                    *::-webkit-scrollbar-track {
                        background: ${scrollbar_backgroundbordercolor};
                    }

                    *::-webkit-scrollbar-thumb {
                        background-color: ${scrollbar_backgroundcolor};
                        border-radius: 9px;
                        border: 1px solid ${scrollbar_backgroundbordercolor};
                    }
                    //End of custom scroll bar
                    /////////////////////////////////////////

                    /////////////////////////////////////////
                    //Remove blue background from inputs
                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    input:-webkit-autofill:active {
                        -webkit-box-shadow: 0 0 0 30px white inset !important;
                    }
                    /////////////////////////////////////////
                }
            `}
        />
    );
};
