import styled from "@emotion/styled";

export const HeaderRoot = styled.div`
    align-items: center;
    background-color: white;
    box-shadow: 0 0 16px -8px rgba(0, 0, 0, 0.4);
    color: black;
    display: flex;
    height: 4.25rem;
    justify-content: space-between;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;

    .headerLogo {
        background-image: url(resources/wild_otter_logo.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 3.5rem;
        height: 3.5rem;
        margin-left: 2rem;
    }
    .headerItems {
        display: flex;
    }
`;

export const HeaderItemRoot = styled.div`
    .container {
        align-items: center;
        color: #707070;
        cursor: pointer;
        font-family: "Poppins";
        font-size: 0.75rem;
        font-weight: 700;
        display: flex;
        height: 4.25rem;
        justify-content: center;
        min-width: 8rem;
        text-transform: uppercase;
    }
    .activecontainer {
        color: white;
        background-color: #2d7dcc;
    }
`;
