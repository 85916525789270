import { isEmptyOrWhitespace, isNullOrUndefined, KeyValuePair } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import Select, { FormatOptionLabelMeta } from "react-select";

import { Box, ErrorExclamation, ICommand, theme } from "Application";
import { EditSelectv2Root, FieldLabel } from "../CommonStyles";

// #region View Props

interface IProps {
    /**
     * An optional id for use with the control.
     */
    id?: string;
    /**
     * An optional prop to state if the control can be cleared.
     */
    canClear?: boolean;
    /**
     * An optional class name for use with the control.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the control. Will be passed back by the command.
     */
    value: () => string;
    /**
     * Text content to display in the control label.
     */
    displayName?: string;
    /**
     * Text content to display in the placeholder.
     */
    placeholder?: string;
    /**
     * Text content to display in the error message.
     */
    validationMessage?: () => string;

    formatOptionLabel?: ((data: any, formatOptionLabelMeta: FormatOptionLabelMeta<any>) => React.ReactNode) | undefined;
    isMulti?: boolean;
    hideSelectedOptions?: boolean;
    noHeader?: boolean;
    optionRenderer?: (item: any) => void;
    options: KeyValuePair[];
    ref?: React.Ref<any>;
    style?: any;
    cy?: string;
}

// #endregion View Props

export const EditSelectv2: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const [selectedOption, setSelectedOption] = useState<any>(null);
    const haveError = !isEmptyOrWhitespace(props.validationMessage?.() as string);

    useEffect(() => {
        //EN: Have to do this because react-select stores the entire key value pair in the value property
        if (!props.isMulti) {
            const initValue = props.options.find((option: KeyValuePair) => option.key === props.value());

            if (initValue) {
                setSelectedOption({ value: initValue.key, label: initValue.text });
            }
        } else {
            if (props.value()) {
                const values = props.value() as any as string[];
                const arr = [];

                for (const item of values) {
                    const initValue = props.options.find((option: KeyValuePair) => option.key === item);
                    if (initValue) {
                        arr.push({ value: initValue.key, label: initValue.text });
                    }
                }

                setSelectedOption(arr);
            }
        }
    }, []);

    useEffect(() => {
        if (!props.isMulti) {
            const initValue = props.options.find((option: KeyValuePair) => option.key === props.value()) as KeyValuePair;

            if (initValue) {
                setSelectedOption({ value: initValue.key, label: initValue.text });
            } else {
                setSelectedOption(null);
            }
        } else {
            const values = props.value() as any as string[];
            const arr = [];

            for (const item of values) {
                const initValue = props.options.find((option: KeyValuePair) => option.key === item);
                if (initValue) {
                    arr.push({ value: initValue.key, label: initValue.text });
                }
            }

            setSelectedOption(arr);
        }
    }, [props.value()]);

    const canDisplayName = (): boolean => {
        return !isEmptyOrWhitespace(props.displayName);
    };

    const getCanClear = (): boolean => {
        return isNullOrUndefined(props.canClear) ? false : props.canClear!;
    };

    const getClassName = (): string => {
        return clsx({
            editselect: true,
            [`editselect-${props.className}`]: !isEmptyOrWhitespace(props.className),
            "editselect-emptylabel": props.displayName === "EMPTY_LABEL",
        });
    };

    const getPlaceholder = (): string => {
        return isEmptyOrWhitespace(props.placeholder) ? "Select or type" : props.placeholder!;
    };

    const onChange = (option: any): void => {
        setSelectedOption(option);
        if (!props.isMulti) {
            props.command.execute({ key: option.value, text: option.label } as KeyValuePair);
        } else {
            const selected = option.map((a: any) => {
                return { key: a.value, text: a.label } as KeyValuePair;
            });

            props.command.execute(selected);
        }
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute;
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const isValidationMessageNotEmpty = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage!());
    };

    const getOptions = (): any => {
        return props.options.map((item: KeyValuePair) => {
            return {
                label: item.text,
                value: item.key,
            };
        });
    };

    const getDataCY = (): string => {
        const regex = /[^a-z]/gi;
        const result = (props.displayName || "").replace(regex, "");

        return props.cy || result;
    };

    // #endregion Code Behind

    const displayLabel = (
        <Box flexBox>
            <FieldLabel htmlFor={props.id} className="placeholder">
                {props.displayName}
            </FieldLabel>
            <ErrorExclamation haveError={haveError} validationMessage={() => getValidationMessage()} />
        </Box>
    );

    return (
        <EditSelectv2Root className={getClassName()}>
            {!props.noHeader && displayLabel}
            <Box flex="1" style={{ ...props.style }}>
                <Select
                    className="editselect-container"
                    classNamePrefix={"editselect"}
                    data-cy={getDataCY()}
                    formatOptionLabel={props.formatOptionLabel}
                    id={props.id}
                    isClearable={getCanClear()}
                    isDisabled={isDisabled()}
                    isMulti={props.isMulti}
                    onChange={onChange}
                    options={getOptions()}
                    placeholder={getPlaceholder()}
                    hideSelectedOptions={props.hideSelectedOptions}
                    ref={props.ref}
                    value={selectedOption}
                />
            </Box>
        </EditSelectv2Root>
    );

    // #endregion Public Interface
});

EditSelectv2.defaultProps = {
    displayName: "",
};
