import "@emotion/react";
import chroma from "chroma-js";
import { AccentColours, IShoothillTheme } from "./IShoothillTheme";

export const pxToRem = (value: number) => {
    return `${value / 16}rem`;
};
export const adobeXDToRem = (fontSize: number, letterSpacing: number): string => {
    return pxToRem((letterSpacing / 1000) * fontSize) + "rem";
};

//Define your custom breakpoints here or accept the default values which are the standard common breakpoints
let customBreakpointValues = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
};

const ThemeColours = (colour: string, text: string, scale = 0.2): AccentColours => {
    return {
        lighter: chroma(colour)
            .brighten(scale * 2)
            .hex(),
        light: chroma(colour).brighten(scale).hex(),
        main: colour,
        dark: chroma(colour).darken(scale).hex(),
        darker: chroma(colour)
            .darken(scale * 2)
            .hex(),
        text: text,
    };
};

export const StyleSheet1: IShoothillTheme = {
    // Spacing in pixels.
    space: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],

    // Sizes. Keep numbers divisible by 2.
    sizes: {
        larger: pxToRem(40),
        large: pxToRem(30),
        medium: pxToRem(26),
        small: pxToRem(20),
    },
    dates: {
        default: "DD/MM/YYYY",
        short: "DD/MM",
        long: "MMMM Do, YYYY",
    },
    fontStyles: {
        h1: {
            fontSize: pxToRem(30),
            fontWeight: 600,
            letterSpacing: adobeXDToRem(30, -15),
            lineHeight: pxToRem(40),
        },
        h2: {
            fontSize: pxToRem(24),
            fontWeight: 600,
            letterSpacing: adobeXDToRem(24, -15),
            lineHeight: pxToRem(30),
        },
        h3: {
            fontSize: pxToRem(18),
            fontWeight: 600,
            letterSpacing: "0rem",
            lineHeight: pxToRem(18),
        },
        h4: {
            fontSize: pxToRem(14),
            fontWeight: 600,
            letterSpacing: "0rem",
            lineHeight: pxToRem(14),
        },
        h5: {
            fontSize: pxToRem(12),
            fontWeight: 600,
            letterSpacing: "0rem",
            lineHeight: pxToRem(12),
        },
        h6: {
            fontSize: pxToRem(10),
            letterSpacing: "0rem",
            lineHeight: pxToRem(10),
            textTransform: "uppercase",
        },
        h7: {
            fontSize: pxToRem(10),
            fontWeight: 600,
            letterSpacing: "0rem",
            lineHeight: pxToRem(10),
        },
        p: {
            fontSize: pxToRem(14),
            letterSpacing: "0rem",
            lineHeight: pxToRem(14),
        },
        button: {
            fontSize: pxToRem(14),
            letterSpacing: pxToRem(0.3),
            textTransform: "uppercase",
        },

        error: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(-0.15),
        },
        field: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(-0.18),
        },
        label: {
            fontSize: pxToRem(10),
            letterSpacing: pxToRem(-0.3),
        },
        tableHeader: {
            fontSize: pxToRem(14),
            fontWeight: 700,
            letterSpacing: pxToRem(-0.18),
            lineHeight: "42px",
        },
        checkboxLabel: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(0.36),
        },
        radioButtonLabel: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(0.36),
        },
        headerOptionLabel: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(0.6),
            textTransform: "uppercase",
        },
    },
    palette: {
        // Use this for your default text.
        common: {
            transparent: "transparent",
            white: "#fff",
            black: "#000",
            // Off-black.
            default: "#040506",
            // Mid blue.
            primary: "#2d7dcc",
            // Mid green.
            secondary: "#83B463",
            // Mid red.
            tertiary: "#c52f2f",
            // Mid orange.
            quarternary: "#f39200",

            // FluentUI focus color. Do not edit!
            focus: "#7fcaf3",
            error: "#c52f3e",
            success: "#23A73D",
            green: "#50AE41",
            purple: "#6679C0",
            blue: "#7CCBE1",
            darkPurple: "#25396F",
        },

        // Use these for your controls - those that have a visual state - focused, hover, pressed etc.
        // Light defines the default state with main and dark being non-default states.

        // Off black background with white text
        default: ThemeColours("#040506", "#fff"),
        // Mid blue background with white text
        primary: ThemeColours("#2d7dcc", "#fff"),
        // Mid green background with white text
        secondary: ThemeColours("#83B463", "#fff"),
        // Mid red background with white text
        tertiary: ThemeColours("#c52f2f", "#fff"),
        // Mid orange background with white text
        quarternary: ThemeColours("#f39200", "#fff"),

        // Component-specific colours.
        checkbox: ThemeColours("#dcdcdc", "#171716"),
        field: ThemeColours("#dcdcdc", "#171716"),

        // Dark transparent background with off-black text
        defaultTr: ThemeColours("#f3f2f1", "#171716"),
        // Light grey border with dark grey for hover/focus and off-black text.
        tableHeader: ThemeColours("#4b4b4b", "#fff"),
        // Mid red background with mid red text
        error: ThemeColours("#c52f3e", "#fff"),
        warning: ThemeColours("#ff9800", "#fff"),
        info: ThemeColours("#2196f3", "#fff"),
        success: ThemeColours("#4caf50", "#fff"),
        hint: ThemeColours("#1b1b1b", "#fff"),
        delete: ThemeColours("#c52f3e", "#fff"),
        //You can still define your colours manually if you want to
        purpleAccent: { lighter: "rgb(198, 51, 255)", light: "rgb(198, 51, 255)", main: "#b800ff", dark: "rgb(128, 0, 178)", darker: "rgb(128, 0, 178)", text: "pink" }, //This is a custom colour
        // Light grey background with white text
        // disabled: { light: "#f3f2f1", main: "#f3f2f1", dark: "#f3f2f1", contrastText: "#a19f9d", text: "#a19f9d" },
        disabled: ThemeColours("#f3f2f1", "#a19f9d"),

        lightPrimary: ThemeColours("#7fcaf3", "#fff"),
    },

    // Fonts and Text.
    defaultFontStyle: {
        fontFamily: `"Poppins", sans-serif`,
        fontSize: pxToRem(16),
        fontWeight: 400,
        letterSpacing: "normal",
        lineHeight: "1.5",
        textTransform: "none",
    },
};
