import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { container } from "tsyringe";

import { AppUrls } from "AppUrls";
import { Box, Show } from "Components";
import { AccountStore } from "Stores/Domain";
import { HeaderItem } from "./HeaderItem";
import { HeaderRoot } from "./HeaderStyles";
import { HeaderViewModel } from "./HeaderViewModel";
import { Sidebar } from "./SideBar";

export const Header: React.FC = observer(() => {
    // #region Code Behind

    const accountStore = container.resolve(AccountStore);
    const [headerViewModel] = useState(() => new HeaderViewModel());

    // #endregion Code Behind

    const renderItems = () => (
        <>
            <HeaderItem path={AppUrls.Client.Users.Users} text={"Users"} viewModel={headerViewModel} />
            {/* <HeaderItem path={AppUrls.Client.Clubs.Clubs} text={"Clubs"} viewModel={headerViewModel} /> */}
            <HeaderItem path={AppUrls.Client.Contents.Contents} text={"Contents"} viewModel={headerViewModel} />
            {/* <HeaderItem path={AppUrls.Client.Settings.Settings} text={"Settings"} viewModel={headerViewModel} /> */}

            <HeaderItem canExecute={() => !accountStore.IsLoggedIn} path={AppUrls.Client.Account.Login} text={"Login"} viewModel={headerViewModel} />
            <HeaderItem canExecute={() => accountStore.IsLoggedIn} path={AppUrls.Client.Account.Logout} text={"Logout"} viewModel={headerViewModel} />
        </>
    );

    return (
        <HeaderRoot>
            <Box className="headerLogo" />
            <Show if={!headerViewModel.isMobile}>
                <Box className="headerItems">{renderItems()}</Box>
            </Show>
            <Show if={headerViewModel.isMobile}>
                <Sidebar width={320} color="white" left>
                    {renderItems()}
                </Sidebar>
            </Show>
        </HeaderRoot>
    );
});
