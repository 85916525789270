import { Stores } from "./Stores";
import { deleteJWT, HttpClient } from "@shoothill/core";
import { container } from "tsyringe";
import { AxiosError, AxiosResponse } from "axios";
import { GlobalHistory } from "./index";
import { Logger } from "index";
import { AppUrls } from "./AppUrls";

export class Startup {
    private httpClient: HttpClient = container.resolve(HttpClient);

    public init = async () => {
        Logger.logDebug("Startup init");
        const stores = new Stores();
        await stores.init();

        //Register intercept handlers for axios calls
        this.httpClient.setOnResponseCallback = this._handleResponse;
        this.httpClient.setOnErrorCallback = this._handleError;
        Logger.logDebug("Finished startup init");
    };

    private _handleResponse = ({ data }: AxiosResponse): any => {
        if (!data.wasSuccessful) {
            if (data.errors && data.errors.length > 0 && data.errors[0].statusCode === 401) {
                deleteJWT().then(() => {
                    GlobalHistory.push(AppUrls.Client.Account.Login);
                });
            }
        }
        return data;
    };

    private _handleError = (error: AxiosError) => {
        Logger.logError("Axios error");
        let res = error.response;
        if (res && res.status == 401) {
            GlobalHistory.push(AppUrls.Client.Account.Login);
        } else if (res && res.status == 404) {
            console.log("We found an error!!!");
        }
        Logger.logError("Looks like there was a problem. Status Code: " + res!.status);
        return Promise.reject(error);
    };
}
