import { Logger } from "../../index";
import { EndpointWithoutRequest, Http } from "../Helpers/BaseEndpoint";
import { InitViewModel } from "./InitViewModel";
import { AccountStatus } from "@shoothill/core";
import { AppSettings, InitialState } from "../Models";

class Response {
    public appInsightsInstrumentationKey: string = "";
    public accountStatus: AccountStatusResponse = new AccountStatusResponse();
    public appSettings: AppSettingsResponse = new AppSettingsResponse();
    public errors: string[] = [];
}

class AccountStatusResponse {
    public id?: string = "";
    public displayName: string = "";
    public isLoggedIn: boolean = false;
    public permissions?: string[] = [];
    public jwt?: string | undefined = "";
    public signalRAccessToken: string = "";
}

class AppSettingsResponse {
    public allowWebCrawlers: boolean = false;
    public forgotPasswordExpiryMins: number = 0;
    public jwtExpiryMins: number = 0;
    public jwtIssuer: string = "";
    public jwtSecret: string = "";
    public refreshExpiryMinutes: number = 0;
    public setNwebSecHeaders: boolean = false;
    public token: string = "";
    public useBasicAuthentication: boolean = false;
    public useDatabase: boolean = true;
    public useHangFire: boolean = true;
    public useSerilog: boolean = false;
    public version: string = "0.0.0";
}

export class InitStateEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: InitViewModel;
    constructor(initViewModel: InitViewModel) {
        super();
        this.viewModel = initViewModel;
        this.verb(Http.Get);
        this.path("/api/initialstate");
    }

    public async HandleResponseAsync(response: Response): Promise<InitialState> {
        Logger.logInformation("Response", response);
        let initialState = {} as InitialState;
        initialState.appInsightsInstrumentationKey = response.appInsightsInstrumentationKey;

        let accountStatus: AccountStatus = {} as AccountStatus;
        accountStatus.id = response.accountStatus.id;
        accountStatus.displayName = response.accountStatus.displayName;
        accountStatus.isLoggedIn = response.accountStatus.isLoggedIn;
        accountStatus.permissions = response.accountStatus.permissions;
        accountStatus.jwt = response.accountStatus.jwt;
        accountStatus.signalRAccessToken = response.accountStatus.signalRAccessToken;
        initialState.accountStatus = accountStatus;

        let appSettings: AppSettings = {} as AppSettings;
        appSettings.allowWebCrawlers = response.appSettings.allowWebCrawlers;
        appSettings.forgotPasswordExpiryMins = response.appSettings.forgotPasswordExpiryMins;
        appSettings.jwtExpiryMins = response.appSettings.jwtExpiryMins;
        appSettings.jwtIssuer = response.appSettings.jwtIssuer;
        appSettings.jwtSecret = response.appSettings.jwtSecret;
        appSettings.refreshExpiryMinutes = response.appSettings.refreshExpiryMinutes;
        appSettings.setNwebSecHeaders = response.appSettings.setNwebSecHeaders;
        appSettings.token = response.appSettings.token;
        appSettings.useBasicAuthentication = response.appSettings.useBasicAuthentication;
        appSettings.useDatabase = response.appSettings.useDatabase;
        appSettings.useHangFire = response.appSettings.useHangFire;
        appSettings.useSerilog = response.appSettings.useSerilog;
        appSettings.version = response.appSettings.version;
        initialState.appSettings = appSettings;

        initialState.errors = response.errors;

        this.viewModel.InitData = initialState;
        this.viewModel.DataReady = true;
        return initialState;
    }
}
