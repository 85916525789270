import clsx from "clsx";
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router";

import { Box } from "Components";
import { HeaderViewModel } from "./HeaderViewModel";
import { HeaderItemRoot } from "./HeaderStyles";
import { container } from "tsyringe";
import { DomainStore } from "Stores/Domain/DomainStore";

interface MenuItemProps {
    text: string;
    path: string;
    canExecute?: () => boolean;
    viewModel: HeaderViewModel;
}

const domainStore = container.resolve(DomainStore);

export const HeaderItem: React.FC<MenuItemProps> = observer((props) => {
    // #region Code Behind

    let location = useLocation();
    let navigate = useNavigate();

    useEffect(() => {
        props.viewModel.setActiveFromLocation(location.pathname, props.path, props.text);
    }, [location]);

    const getClassNames = () => {
        return clsx({
            ["container"]: true,
            ["activecontainer"]: props.viewModel.isActiveMenuItem(props.text),
        });
    };

    const onClick = () => {
        domainStore.setSideBarClosed(true);
        props.viewModel.setActiveMenuItem(props.text);
        navigate(props.path);
    };

    // #endregion Code Behind

    if (!props.canExecute!()) {
        return null;
    }

    return (
        <HeaderItemRoot onClick={onClick}>
            <Box className={getClassNames()}>
                <Box>{props.text}</Box>
            </Box>
        </HeaderItemRoot>
    );
});

HeaderItem.defaultProps = {
    canExecute: () => true,
};
