import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { CSSProperties, forwardRef, RefObject, useRef } from "react";
import {
    AccentColours,
    getThemePaletteOption,
    getThemeShapeOption,
    getThemeSizeOption,
    ICommand,
    theme,
    themePaletteOptions,
    themeShapeOptions,
    themeSizeOptions,
} from "../../../Application";
import styled from "@emotion/styled";
import { space, SpaceProps } from "styled-system";

/*
const InternalButton = styled.button<SpaceProps>`
    {...props}
    ${space}; 
`;
*/

type Props = {
    size: string;
    paletteColour: AccentColours;
    shape: string;
} & SpaceProps;

const StyledButton = styled.button<Props>`
    min-height: 30px;
    padding: 8px 30px;
    text-transform: ${theme.fontStyles.button.textTransform ?? theme.defaultFontStyle.textTransform};
    font-family: ${theme.fontStyles.button.fontFamily ?? theme.defaultFontStyle.fontFamily};
    font-size: ${theme.fontStyles.button.fontSize ?? theme.defaultFontStyle.fontSize};
    font-weight: ${theme.fontStyles.button.fontWeight ?? theme.defaultFontStyle.fontWeight};
    letter-spacing: ${theme.fontStyles.button.letterSpacing ?? theme.defaultFontStyle.letterSpacing};
    line-height: ${theme.fontStyles.button.lineHeight ?? theme.defaultFontStyle.lineHeight};
    background-color: ${(p) => p.paletteColour.main};
    color: ${(p) => p.paletteColour.text};
    border-radius: p.shape;
    border: 0;
    cursor: pointer;
    flex-basis: 0;
    white-space: nowrap;
    &:hover {
        background-color: ${(p) => p.paletteColour.dark};
    }
    &:active {
        background-color: ${(p) => p.paletteColour.light};
    }
    &[disabled] {
        background-color: ${(p) => p.paletteColour.main};
    }
`;

/**
 * Button interface.
 */
type IButtonProps = {
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the button. Will be passed back by the command.
     */
    value?: string | number | boolean;
    /**
     * Element to display on the button.
     */
    displayName?: JSX.Element | string;
    /**
     * An icon to display on the button.
     */
    icon?: JSX.Element;
    /**
     * Styling of the button.
     */
    style?: CSSProperties | undefined;
    /**
     * The size of the button - use this if using generic button.
     */
    size?: themeSizeOptions;
    /**
     * The color of the button - use this if using the generic button.
     */
    paletteColor?: themePaletteOptions;
    /**
     * The shape of the button - use this if using the generic button.
     */
    shape?: themeShapeOptions;
    /**
     * The button type.
     */
    type?: "button" | "submit" | "reset";
    /**
     * Use to make cypress testing easier
     */
    cy?: string;
} & SpaceProps;

export const Button = forwardRef((props: IButtonProps, ref: any) => {
    const { className, command, value, displayName, icon, style, type, ...rest } = props;
    // #region Code Behind
    const size = getThemeSizeOption(props.size);
    const paletteColor = getThemePaletteOption(props.paletteColor);
    const shape = getThemeShapeOption(props.shape);

    const getClassNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const getType = (): "submit" | "reset" | "button" => {
        return !isEmptyOrWhitespace(props.type) ? props.type! : "button";
    };

    const onClick = (ev: any): void => {
        ev.stopPropagation();
        props.command.execute(props.value);
    };

    const getIcon = (): any | undefined => {
        return props.icon ? props.icon : undefined;
    };
    const getDataCY = (): string => {
        try {
            let regex = /[^a-z]/gi;
            let result = ((props.displayName as string) || "").replace(regex, "");
            return props.cy || result;
        } catch (error) {
            return "";
        }
    };
    // #endregion Code Behind
    return (
        <StyledButton
            {...rest}
            size={size}
            paletteColour={paletteColor}
            shape={shape}
            style={props.style}
            onClick={onClick}
            disabled={isDisabled()}
            className={getClassNames()}
            type={getType()}
            data-cy={getDataCY()}
            ref={ref}
        >
            {props.displayName}
        </StyledButton>
    );
});
