import styled from "@emotion/styled";

export const ApplicationRoot = styled.div`
    align-items: center;
    animation: fadeIn 1s;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;

    .logo-container {
        position: relative;
        margin: 0 auto;
        margin-bottom: 1rem;
        width: 8rem;

        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }

        svg {
            bottom: 0;
            height: 100%;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
            transform-origin: center center;
            width: 100%;

            &.rotate {
                animation: rotate 2s linear infinite;
            }
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes rotate {
        100% {
            transform: rotate(-360deg);
        }
    }

    .message {
        color: #707070;
        font-family: Poppins, sans-serif;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
    }

    .additional-message {
    }

    .reset-container {
        display: flex;
        justify-content: center;
        margin-top: 1rem;

        button {
            font-size: 0.75rem;
            padding: 0.25rem 2rem;
        }
    }
`;
