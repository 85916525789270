import React from "react";

import { ICommand } from "Application";
import { ClearTextRoot } from "Components/Primitives/CommonStyles";

interface Props {
    command: ICommand;
    className?: string;
}

export const ClearText: React.FC<Props> = (props: Props) => {
    const onClick = () => {
        if (props.command.canExecute()) {
            props.command.execute();
        }
    };

    return <ClearTextRoot className={props.className} onClick={onClick} />;
};
