import "tippy.js/dist/tippy.css";
import Tippy from "@tippyjs/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { theme } from "Application";
import { ExclamationIconSVG } from "Assets/Icons/ExclamationIconSVG";
import { ErrorExclamationViewRoot } from "./Primitives/CommonStyles";

interface Props {
    haveError: boolean;
    style?: any;
    validationMessage: () => string;
}

export const ErrorExclamation: React.FC<Props> = observer((props: Props) => {
    if (!props.haveError) {
        return null;
    }

    return (
        <ErrorExclamationViewRoot>
            <Tippy content={<span>{props.validationMessage()}</span>}>
                <ExclamationIconSVG backgroundcolor={theme.palette.error.main} color={theme.palette.error.text} />
            </Tippy>
        </ErrorExclamationViewRoot>
    );
});
