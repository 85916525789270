import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";

import { ICommand } from "Application";
import { Box, ErrorExclamation } from "Components/index";
import { CheckBoxRoot } from "../CommonStyles";
import { Label } from "../Labels/Label";

/**
 * Checkbox interface.
 */
interface IProps {
    /**
     * An optional class name for use with the checkbox.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the checkbox. Will be passed back by the command.
     */
    value: () => boolean;
    /**
     * Text content to display on the checkbox.
     */
    displayName?: string;
    /**
     * Used if we have an array of checkboxes
     */
    index?: number;
    /**
     * Get validation message
     */
    validationMessage?: () => string;
    /**
     * Cypress.
     */
    cy?: string;
}

export const CheckBox: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const getClassName = () => {
        return clsx({
            checkbox: true,
            [`checkbox-${props.className}`]: !isEmptyOrWhitespace(props.className),
        });
    };

    const canDisplayErrorMessage = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage?.() as string);
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (props.command.execute) {
            props.command.execute(event.target.checked, props.index);
        }
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const getDataCY = (): string => {
        let regex = /[^a-z]/gi;
        let result = (props.displayName || "").replace(regex, "");

        return props.cy || result;
    };

    // #endregion Code Behind

    return (
        <Box alignSelf={"end"}>
            <CheckBoxRoot className={getClassName()}>
                <input checked={props.value()} data-cy={getDataCY()} disabled={isDisabled()} onChange={onChange} type="checkbox" />
                <Box style={{ display: "flex", columnGap: "0" }}>
                    <Label fontStyle="checkboxLabel">{props.displayName}</Label>
                    <Box mt={"-20px"}>
                        <ErrorExclamation haveError={canDisplayErrorMessage()} validationMessage={() => getValidationMessage()} />
                    </Box>
                </Box>
            </CheckBoxRoot>
        </Box>
    );
});
