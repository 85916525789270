export class GlobalAppUrls {
    public Client = {
        Account: {
            ForgotPassword: "/account/forgotpassword",
            Login: "/account/login",
            Logout: "/account/logout",
            ResetPassword: "/account/resetpassword/:token",
            SessionExpired: "/sessionexpired",
        },
        Clubs: {
            Clubs: "/clubs",
            Club: "/clubs/club",
            ClubWithId: "/clubs/club/:id",
        },
        Contents: {
            Contents: "/contents",
            Points: {
                Points: "/contents/points",
                Point: "/contents/points/point",
                PointWithId: "/contents/points/point/:id",
            },
            Routes: {
                Routes: "/contents/routes",
                Route: "/contents/routes/route",
                RouteWithId: "/contents/routes/route/:id",
            },
            Reports: {
                Reports: "/contents/reports",
                HazardChange: "/contents/reports/hazard-change/:id",
                SuggestedChange: "/contents/reports/suggested-change/:id",
                RatingsSuggestion: "/contents/reports/ratings-suggestion/:id",
                Comments: {
                    Comments: "/contents/comments",
                    Point: "/contents/comments/comment/point/:id",
                    Route: "/contents/comments/comment/route/:id",
                },
                Reported: {
                    Point: "/contents/reports/reported/point/:id",
                    Route: "/contents/reports/reported/route/:id",
                },
            },
        },
        Settings: {
            Settings: "/settings",
            Setting: "/settings/setting",
            SettingWithid: "/settings/setting/:id",
        },
        Users: {
            Users: "/users",
            User: "/users/user",
            UserWithId: "/users/user/:id",
        },
        Error: "/error",
        Home: "/",
        // Old stuff below
        Admin: {
            AdminHome: "/admin/dashboard/userslist",
            LogViewer: "/admin/logviewer",
        },
    };

    public Server = {
        Account: {
            CreateNewPassword: "/api/account/createnewpassword",
            ForgotPassword: "/api/account/forgotpassword",
            Login: "/api/account/login",
            Logout: "/api/account/logout",
            Register: "/api/account/register",
            ResendEmailConfirmation: "api/account/resendemailconfirmation",
            ResetPassword: "/api/account/resetpassword",
            SetPassword: "/api/account/setpassword",
            VerifyToken: "/api/account/resetpasswordtoken",
            User: {
                DeleteUser: "/api/user/delete",
                DeleteRole: "/api/role/delete",
            },
        },
        Clubs: {
            ViewAllClubs: "/api/admin/clubs/viewallclubs",
        },
        Points: {
            DeletePoint: "/api/admin/points/deletepoint/{pointid}",
            GetPointByPointId: "/api/admin/points/getpointbypointid/{pointid}",
            GetPointRelated: "/api/admin/points/getpointrelated",
            UpsertPoint: "/api/admin/points/upsertpoint",
            ViewAllPoints: "/api/admin/points/viewallpoints",
            SetIsPublished: "/api/admin/points/setispublished",
        },
        Reports: {
            ViewAllReports: "/api/admin/reports/viewallreports",
            ViewAllComments: "/api/admin/reports/viewallcomments",
            ReportAction: "/api/admin/reports/action",
            Comments: {
                Point: "/api/admin/reports/comment/point/:id",
                Route: "/api/admin/reports/comment/route/:id",
            },
            Report: {
                Point: "/api/admin/reports/report/point/:id",
                Route: "/api/admin/reports/report/route/:id",
            },
            RatingsSuggestion: "/api/admin/reports/ratings-suggestion/:id",
        },
        Routes: {
            DeleteRoute: "/api/admin/routes/deleteroute/{routeid}",
            GetRouteByRouteId: "/api/admin/routes/getroutebyrouteid/{routeid}",
            GetRouteRelated: "/api/admin/routes/getrouterelated",
            UpsertRoute: "/api/admin/routes/upsertroute",
            ViewAllRoutes: "/api/admin/routes/viewallroutes",
            SetIsPublished: "/api/admin/routes/setispublished",
        },
        Levels: {
            GetGauges: "/api/admin/levels/gauges",
            GetRatingsByMeasureId: "/api/admin/levels/measure/:measureType/:measureId/ratings",
            GetDataPointByMeasureId: "/api/admin/levels/measure/:measureType/:measureId/datapoints",
        },
        Settings: {
            ViewAllSettings: "/api/admin/settings/viewallsettings",
        },
        Users: {
            ViewAllUsers: "/api/admin/users/viewallusers",
            GetUserByUserId: "/api/admin/users/getuserbyuserid/:id",
            UpsertUser: "/api/admin/users/upsert",
            GetUserRelated: "/api/admin/users/getuserrelated",
            ResetUserPassword: "/api/admin/users/user/:id/resetpassword",
            DeleteUser: "/api/admin/users/user/:id",
        },
        Admin: {
            ResetFailedLoginAttemptsCount: "/api/user/ResetLoginAttempts",
            GetRoles: "/api/roles/getall",
        },
    };
}

export const AppUrls = new GlobalAppUrls();
