import styled from "@emotion/styled";
import React from "react";
import { Outlet } from "react-router";

import { Header } from "../Header/Header";
import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";

const Root = styled.div`
    // This is the height of the header. We need thios as the header has
    // position "fixed".
    padding-top: 4.25rem;

    // These 4 properties are required for correct scroll behaviour.
    // Do not touch.
    // The outlet will be responsible for scrolling.
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
`;

export const DefaultLayout: React.FC<{}> = (props) => {
    if (!props) {
        return <>Props undefined</>;
    }

    if (window.IE11) {
        return <UnsupportedBrowserView />;
    }

    return (
        <>
            <Header />
            <Root>
                <Outlet />
            </Root>
        </>
    );
};
