import { isEmptyOrWhitespace, isNullOrUndefined, KeyValuePair } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";

import { Box, ErrorExclamation, ICommand } from "Application";
import { FieldLabel, RadioButtonRoot, RadioButtonsRoot } from "../CommonStyles";
import { Label } from "../Labels/Label";

/**
 * Radio buttons interface.
 */
interface IProps {
    /**
     * An optional id for use with the control.
     */
    id?: string;
    /**
     * An optional class name for use with an individual radio button.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the checkbox. Will be passed back by the command.
     */
    value: () => string;
    /**
     * Text content to display on the radio buttons.
     */
    displayName?: string;
    /**
     * The direction in which to layout the radio buttons.
     */
    horizontal?: boolean;
    /**
     * The options from which to generate a collection of buttons.
     */
    options: KeyValuePair[];
    /**
     * Cypress.
     */
    cy?: string;
    /**
     * Text content to display in the error message.
     */
    validationMessage?: () => string;

    noHeader?: boolean;
}

export const RadioButtons: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const haveError = !isEmptyOrWhitespace(props.validationMessage?.() as string);

    const getGroupClassName = (): string => {
        return clsx({
            radiobuttons: true,
            [`radiobuttons-${props.className}`]: !isEmptyOrWhitespace(props.className),
            "radiobuttons-horizontal": props.horizontal,
            "radiobuttons-emptylabel": props.displayName === "EMPTY_LABEL",
        });
    };

    const getClassName = (): string => {
        return clsx({
            radiobutton: true,
            [`radiobutton-${props.className}`]: !isEmptyOrWhitespace(props.className),
        });
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute;
    };

    const isChecked = (key: string): boolean => {
        return key === props.value();
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const onChange = (key: string): void => {
        props.command.execute(key);
    };

    const getDataCY = (): string => {
        let regex = /[^a-z]/gi;
        let result = (props.displayName || "").replace(regex, "");

        return props.cy || result;
    };

    // #endregion Code Behind

    const displayLabel = (
        <Box flexBox>
            <FieldLabel htmlFor={props.id} className="placeholder">
                {props.displayName}
            </FieldLabel>
            <ErrorExclamation haveError={haveError} validationMessage={() => getValidationMessage()} />
        </Box>
    );

    return (
        <RadioButtonsRoot className={getGroupClassName()}>
            {!props.noHeader && displayLabel}
            <Box className="radiobutton-container">
                {props.options.map((option: KeyValuePair) => {
                    const htmlForId = `radio-${option.key}`;

                    return (
                        <RadioButtonRoot key={option.key} className={getClassName()}>
                            <input
                                checked={isChecked(option.key)}
                                data-cy={getDataCY()}
                                disabled={isDisabled()}
                                id={htmlForId}
                                onChange={() => onChange(option.key)}
                                type="radio"
                            />
                            <Box style={{ display: "flex", columnGap: "0" }}>
                                <Label fontStyle="radioButtonLabel" htmlFor={htmlForId}>
                                    {option.text}
                                </Label>
                            </Box>
                        </RadioButtonRoot>
                    );
                })}
            </Box>
        </RadioButtonsRoot>
    );
});

RadioButtons.defaultProps = {
    horizontal: true,
};
